import { defineStore } from "pinia"
import type { Ref } from "vue"
import { computed, ref } from "vue"
import type { RemovableRef } from "@vueuse/core"
import { useStorage } from "@vueuse/core"
import type { JurisdictionResponse } from "~/types/jurisdictionResponse"

export const useJurisdictionStore = defineStore("jurisdiction", () => {
  const selectedJurisdictionInStorage = useStorage("pinia/jurisdiction/selectedJurisdiction", "")
  const jurisdictionsInStorage: RemovableRef<JurisdictionResponse[]> = useStorage(
    "pinia/jurisdiction/jurisdictions",
    []
  )

  const selectedJurisdictionName = ref("")

  const selectedJurisdiction = computed((): string => {
    if (
      (selectedJurisdictionName.value === "" || !selectedJurisdictionName.value) &&
      selectedJurisdictionInStorage.value !== ""
    ) {
      return selectedJurisdictionInStorage.value
    }

    return selectedJurisdictionName.value
  })

  const jurisdictionNeedsToBeSelected = ref(false)

  const selectJurisdiction = (jurisdiction: string) => {
    selectedJurisdictionInStorage.value = jurisdiction
    jurisdictionNeedsToBeSelected.value = false
    selectedJurisdictionName.value = jurisdiction
  }

  const jurisdictions: Ref<JurisdictionResponse[]> = ref([])

  const getJurisdictions = computed((): JurisdictionResponse[] => {
    if (jurisdictions.value.length === 0 && jurisdictionsInStorage.value.length) {
      return jurisdictionsInStorage.value
    }

    return jurisdictions.value
  })

  const readOnly = computed((): boolean | undefined => {
    const selectedJurisdiction = jurisdictions.value.find((it) => it.jurisdiction === selectedJurisdictionName.value)

    if (!selectedJurisdiction) return undefined
    else return selectedJurisdiction.readOnly
  })

  const setJurisdictions = (newJurisdictions: JurisdictionResponse[]) => {
    jurisdictionsInStorage.value = jurisdictions.value
    jurisdictions.value = newJurisdictions
  }

  return {
    selectedJurisdictionInStorage,
    jurisdictionsInStorage,
    jurisdictions,
    selectedJurisdictionName,
    jurisdictionNeedsToBeSelected,
    getJurisdictions,
    selectedJurisdiction,
    readOnly,
    setJurisdictions,
    selectJurisdiction,
  }
})
